export const sortData = (data, sortType) => {
  switch (sortType) {
    case "name a-z":
      return data.sort((a, b) => {
        if (a.FullyQualifiedName < b.FullyQualifiedName) {
          return -1;
        }
        if (a.FullyQualifiedName > b.FullyQualifiedName) {
          return 1;
        }
        return 0;
      });

    case "name z-a":
      return data.sort((a, b) => {
        if (a.FullyQualifiedName < b.FullyQualifiedName) {
          return 1;
        }
        if (a.FullyQualifiedName > b.FullyQualifiedName) {
          return -1;
        }
        return 0;
      });

    case "email a-z":
      return data.sort((a, b) => {
        if (a.email < b.email) {
          return -1;
        }
        if (a.email > b.email) {
          return 1;
        }
        return 0;
      });

    case "email z-a":
      return data.sort((a, b) => {
        if (a.email < b.email) {
          return 1;
        }
        if (a.email > b.email) {
          return -1;
        }
        return 0;
      });

    case "type a-z":
      return data.sort((a, b) => {
        if (a.customerType < b.customerType) {
          return -1;
        }
        if (a.customerType > b.customerType) {
          return 1;
        }
        return 0;
      });

    case "type z-a":
      return data.sort((a, b) => {
        if (a.customerType < b.customerType) {
          return 1;
        }
        if (a.customerType > b.customerType) {
          return -1;
        }
        return 0;
      });

    case "balance ascending":
      return data.sort((a, b) => {
        return a.Balance - b.Balance;
      });

    case "balance descending":
      return data.sort((a, b) => {
        return b.Balance - a.Balance;
      });

    default:
      return data;
  }
};
