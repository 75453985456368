import firebase from 'firebase/app';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyB_mMmnCA63271vF7qwHJ1ItoDN0BAjJeA",
    authDomain: "members-41ec8.firebaseapp.com",
    projectId: "members-41ec8",
    storageBucket: "members-41ec8.appspot.com",
    messagingSenderId: "52118518767",
    appId: "1:52118518767:web:5736cfc28c61aa4488541a"
};

if (!firebase.length) {
    firebase.initializeApp(config);
}

export const authInstance = firebase.auth;
