import React from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { sortData } from '../../utility/sort';
import styles from './MembersTable.module.scss';

const MembersTable = ({ data, keywords, entriesCount, emailFilter, sortType, userType, showModal, setSelectedMember }) => {
    let members = data && sortData([...data], sortType).filter(item => {
        const name = item.lastName.toLowerCase();
        const email = item.email && item.email.toLowerCase();

        return name.includes(keywords.toLowerCase())
            || ((emailFilter && email) && email.includes(keywords.toLowerCase()));

    }).slice(0, entriesCount || data.length);

    const viewMemberHandler = (member) => {
        showModal();
        setSelectedMember(member);
    };


    return (
        <div className={styles.Wrapper}>
            <Table striped bordered className={styles.Table}>
                <thead>
                <tr>
                    <th style={{ width: userType === "manager" ? "30%" : "90%" }}>
                        Account Name
                    </th>
                    { userType ==="manager" && <th className={styles.EmailHeader}>
                        Email
                    </th> }
                    { userType ==="manager" && <th className={styles.customerTypeHeader}>Phone</th> }
                    { userType ==="manager" && <th className={styles.customerTypeHeader}>Type</th> }
                    { userType ==="manager" && <th className={styles.BalanceHeader}>Balance</th> }
                    { userType ==="manager" && <th className={styles.BalanceHeader}>CheckIns</th> }
                    <th className={styles.ViewHeader}>View</th>
                </tr>
                </thead>
                <tbody>
                { members && members.map(member => (
                    <tr key={member.customerIndex} >
                        <td>{ member.firstName } { member.lastName} </td>
                        { userType === "manager" && <td>{ member.email }</td>}
                        { userType === "manager" && <td>{ member.mobile }</td>}
                        { userType === "manager" && <td>{ member.customerType}</td>}
                        { userType === "manager" && <td>{ member.balance }</td>}
                        { userType === "manager" && <td>{ member.checkins }</td>}
                        <td>
                            <Button variant="info" onClick={() => viewMemberHandler(member)}>View</Button>
                        </td>
                    </tr>
                )) }

                { members === null && (
                    <>
                        <tr />
                        <tr>
                            <td colSpan={5}>
                                <div className={styles.LoadingWrapper}>
                                    <Spinner animation="border" />
                                </div>
                            </td>
                        </tr>
                    </>
                )}
                </tbody>
            </Table>
        </div>
    );
};

export default MembersTable;
