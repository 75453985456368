import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import styles from './Loading.module.scss';

const Loading = ({ value, text }) => {
    return (
        <>
            { value && (
                <div className={styles.Wrapper}>
                    <Spinner animation="border" role="status">
                        { text && <span className="visually-hidden">{ text }</span> }
                    </Spinner>
                </div>
            ) }
        </>
    );
};

export default Loading;
