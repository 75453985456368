import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './CheckInModal.module.scss';
import './modalOverwrite.css';
import {listCheckins} from "../../api/calls";

const CheckInModal = ({ show, onHide, member, onCheckIn, userType }) => {
    const [checkIns, setCheckIns] = useState([]);

    const checkInClickHandler = (member) => {
        onCheckIn(member);
        closeModalHandler();
    };

    const closeModalHandler = () => {
        setCheckIns([]);
        onHide();
    };

    useEffect(() => {
        if (member) {
            listCheckins(member).then(data => setCheckIns(data))
        }
    }, [member]);

    return (
        <>
            { member && (
                <Modal show={show} onHide={closeModalHandler} size="lg" centered>
                    <Modal.Body>
                        <h4>
                            { member.firstName } { member.lastName}
                            { member.Balance <= 0 && <FontAwesomeIcon icon={faCheck} className={[styles.Icon, styles.CheckIcon].join(' ')} />}
                            { member.Balance > 0 && <FontAwesomeIcon icon={faTimes} className={[styles.Icon, styles.CrossIcon].join(' ')} />}
                        </h4>
                        { userType === "manager" && <p className="mb-0">Balance: { member.balance }</p> }
                        { userType === "manager" && <p className="mb-0">Email: { member.email }</p> }
                        { userType === "manager" && <p className="mb-0">Primary Phone: { member.mobile }</p> }

                        { member.customerType != "Tennis"  && <Button className="mt-3" onClick={() => checkInClickHandler(member)}>Check In</Button> }
                        { member.customerType === "Tennis"  && <p className="mt-3 font-weight-bold">Tennis Only Membership</p> }

                        <div className={styles.CheckInsList}>
                            
                            { userType === "manager" &&  checkIns.reverse().map(time => {
                                var dateFormat = require("dateformat");
                                <p>Visits to the Club</p>
                                return <p key={time}>{dateFormat(time, " mmmm dS, yyyy, h:MM TT")}</p>
                            }) }
                        </div>

                        
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
};

export default CheckInModal;
